import React from 'react';

import Gallery from 'react-photo-gallery';
import ResizeObserver from 'resize-observer-polyfill';

import Img from 'gatsby-image';

import injectSheet from 'react-jss';

import Box from "./Box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {faAngleDoubleDown} from "@fortawesome/free-solid-svg-icons/faAngleDoubleDown";

const Image = ({ index, onClick, photo, margin, direction, top, left }) => {
    const imgStyle = {
        width: photo.width,
        height: photo.height,
        margin: margin,
    };
    if (direction === 'column') {
        imgStyle.position = 'absolute';
        imgStyle.left = 0;
        imgStyle.top = 0;
        imgStyle.transform = 'translate3d('+left+'px, '+top+'px, 0)';
//        imgStyle.left = left;
//        imgStyle.top = top;
    }

    if(onClick){
        imgStyle.cursor = 'pointer';
    }


    return (
        <a
            onClick={onClick ? event =>  onClick(event, { photo, index }) : null}
        >
            <Img
                style={imgStyle}
                fluid={photo.fluid}
            />
        </a>
    );
};

const styles = theme => {
    return {
        controller: {
            alignSelf: 'center',
            margin: 'auto',
            cursor: 'pointer',
        },
        gallery: {
            width: '100%',
        },
        controllerButton: {
            fontSize: '1.5em',
            width: '6rem',
            margin: 'auto',
            paddingBottom: '.25rem',
            paddingTop: '.25rem',
            border: '4px solid '+theme.palette.primary.dark,
            borderTop: 0,
            borderBottomLeftRadius: '4rem',
            borderBottomRightRadius: '4rem',
            textAlign: 'center',
            cursor: 'pointer',
            background: theme.palette.primary.dark,
            color: theme.palette.background,
            '& svg':{
                transition: 'padding .25s ease-in',
            },
            '&:hover': {
                '& svg': {
                    paddingTop: '.4rem',
                },
                color: theme.palette.primary.dark,
                background: theme.palette.background,
            }
        },
    };
};

class CollapsableGallery extends React.Component{
    state = {
        collapsed: true,
        containerWidth: 0
    };

    constructor() {
        super();

        this.toggleGallery = this.toggleGallery.bind(this);
    }

    componentDidMount() {
        this.animationFrameID = null;
        this.observer = new ResizeObserver(entries => {
            // only do something if width changes
            const newWidth = entries[0].contentRect.width;
            if (this.state.containerWidth !== newWidth) {
                // put in an animation frame to stop "benign errors" from
                // ResizObserver https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
                this.animationFrameID = window.requestAnimationFrame(() => {
                    this.setState({ containerWidth: Math.floor(newWidth) });
                });
            }
        });
        this.observer.observe(this._gallery);
    }
    componentWillUnmount() {
        this.observer.disconnect();
        window.cancelAnimationFrame(this.animationFrameID);
    }

    toggleGallery(){
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    render(){
        const {collapsed, containerWidth} = this.state;
        const {imageSet, header, classes} = this.props;

        let columns = 1;
        if (containerWidth >= 400) columns = 2;
        if (containerWidth >= 900) columns = 3;
        if (containerWidth >= 1500) columns = 4;

        return (
            <>
            <Box
                className={'container'}
                header={header}
                margin={false}
            >
                <div className={classes.gallery} ref={c => (this._gallery = c)}>
                    <Gallery
                        photos={collapsed? imageSet.slice(0,columns): imageSet}
                        onClick={(event, obj) => this.props.onClick(obj.index)}
                        direction={collapsed? "row": "column"}
                        columns={columns}
                        ImageComponent={Image}
                    />
                </div>
            </Box>
            {collapsed && <div className={classes.controllerButton} onClick={() => this.toggleGallery()}><FontAwesomeIcon icon={faAngleDoubleDown}/></div>}
            </>
        )
    }
}

export default injectSheet(styles)(CollapsableGallery);