import {connect} from "react-redux";

import {openLightbox} from '../actions';

import Gallery from "../components/Gallery";

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = dispatch => ({
    onClick: (image) => dispatch(openLightbox(image)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Gallery);
