import React from 'react'
import injectSheet from 'react-jss';

//import spinner from '../assets/ajax-loader.gif';
import spinner from '../assets/logo/slam2018_tuerkis_nob_bg.svg';

const styles = theme => ({
    '@keyframes spinner': {
        '0%': {
            transform: 'rotateZ(0deg)',
        },
        '100%': {
            transform: 'rotateZ(360deg)',
        }
    },

    root: {
        position: 'absolute',
        zIndex: 5,
        left:0, top:0, right:0, bottom:0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > img': {
            animation: 'spinner 5s linear infinite',
            position: 'absolute',
            width: '4rem',
            height: '4rem',
        },
    }
});

const Spinner = ({classes}) => {
    return (
        <div className={classes.root}><img src={spinner}/></div>
    );
};

export default injectSheet(styles())(Spinner);