import React from 'react';
import {graphql} from 'gatsby';
import Img from 'gatsby-image'
import 'dayjs/locale/de';
import dayjs from "dayjs";
import injectSheet from 'react-jss';

import Layout from "../containers/layout";
import SubPage from "../containers/subpage";
import VisibleLightBox from '../containers/VisibleLightBox';
import LightBoxGallery from "../containers/LightBoxGallery";
import Spinner from '../components/Spinner';
import GalleryElement from "../components/GalleryElement";
import SmallBox from "../components/SmallBox";

const styles = theme => ({
    navigation: {
        display: 'flex',
        marginTop: '4rem',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xxs')]: {
            flexDirection: 'column',
        }
    },
    navigationBox: {
        maxWidth: '20em',
        [theme.breakpoints.down('xxs')]: {
            marginLeft: 0,
            marginRight: 0,
            maxWidth: '100%',
        }
    },
});

const GalleryDetailPage = ({data, classes}) => {
    let {name, title, images, event} = data.gallery;
    images = images.map((file) => {
        let fluid = file.fluid;
        let width = Math.round(1 + Math.random() * 2);
        let height = Math.round(1 + Math.random() * 2);
        let caption = title+": "+file.name + ' ©'+name;
        return {  ...fluid, width, height, fluid, caption };
    });

    let fluid = images[0].fluid;
    if(event && event.featured_media.localFile) {
        fluid = event.featured_media.localFile.childImageSharp.fluid;
    }

    let additionalHeader = null;
    let untertitel = null;

    let navigation = (name+'-'+title).split(' ').join('_');

    if(event){
        if(event.custom_fields.untertitel){
            untertitel = ' - '+event.custom_fields.untertitel;
        }
        const date = dayjs(event.custom_fields.wpcf_veranstaltungsdatum*1000).locale('de');
        navigation = date.format('D-MM');
        additionalHeader = (
            <h4>
                Datum: {date.format('D. MMMM, YYYY')}<br/>
                Location: {event.location.title}
            </h4>
        );
    }

    let gatsbyImg = (<Img fluid={fluid}/>);

    let next;
    if(data.next && data.next.edges.length>0) {
        const {name, title, slug, previewImage, imageCount, event} = data.next.edges[0].node;

        let date = null;
        if (event) {
            date = dayjs(event.custom_fields.wpcf_veranstaltungsdatum * 1000).locale('de');
        }


        next = (
            <GalleryElement
                image={previewImage}
                count={imageCount}
                name={name}
                title={title}
                slug={slug}
                date={date}
                alt={true}
            />
        );
        next = (
            <SmallBox
                header={'Nachher'}
                className={classes.navigationBox}
            >
                <div>
                    {next}
                </div>
            </SmallBox>
        );
    }

    let previous;
    if(data.previous) {
        const {name, title, slug, previewImage, imageCount, event} = data.previous.edges[0].node;

        let date = null;
        if (event) {
            date = dayjs(event.custom_fields.wpcf_veranstaltungsdatum * 1000).locale('de');
        }


        previous = (
            <GalleryElement
                image={previewImage}
                count={imageCount}
                name={name}
                title={title}
                slug={slug}
                date={date}
            />
        );
        previous = (
            <SmallBox
                header={'Vorher'}
                className={classes.navigationBox}
            >
                <div>
                    {previous}
                </div>
            </SmallBox>
        );
    }

    return (
        <Layout pageTitle={"Bilder"}>
            <SubPage
                image={gatsbyImg}
                back={'/bilder/#' + navigation}
                header={(
                    <>
                        <h2>{title}{untertitel}</h2>
                        {additionalHeader}
                    </>
                )}
            >
                <LightBoxGallery
                    imageSet={images}
                    header={'Bilder'}
                />
                <VisibleLightBox
                    imageSets={images}
                    spinner={Spinner}
                />
                <div className={classes.navigation + ' container'}>
                    {previous}{next}
                </div>
            </SubPage>
        </Layout>
    )
};

export default injectSheet(styles)(GalleryDetailPage);

export const query = graphql`
    query GalleryDetailPage($id: String!, $next: String!, $previous: String!) {
        gallery: gallery(id: {eq: $id}){
            name
            title
            event {
                custom_fields{
                    wpcf_veranstaltungsdatum
                    untertitel: wpcf_untertitel
                }
                location {
                    title
                }
                featured_media {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 2048) {
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
            images: childrenGalleryImage {
                name
                fluid {
                    aspectRatio
                    src
                    srcSet
                    sizes
                    tracedSVG
                }
            }
        }
        next: allGallery(filter: {id: {eq: $next}}){
            edges{
                node{
                    name
                    title
                    slug
                    event {
                        custom_fields{
                            wpcf_veranstaltungsdatum
                        }
                    }
                    previewImage {
                        fluid {
                            aspectRatio
                            src
                            srcSet
                            sizes
                            tracedSVG
                        }
                    }
                    imageCount
                }
            }                
        }
        previous: allGallery(filter: {id: {eq: $previous}}){
            edges{
                node{
                    name
                    title
                    slug
                    event {
                        custom_fields{
                            wpcf_veranstaltungsdatum
                        }
                    }
                    previewImage {
                        fluid {
                            aspectRatio
                            src
                            srcSet
                            sizes
                            tracedSVG
                        }
                    }
                    imageCount
                }
            }                
        }
    }
`;