import React from "react";
import injectSheet from 'react-jss';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import Link from "../components/Link";

const styles = theme => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerContent: {
        position: 'relative',
    },
    headerImage: {
        flex: '1 1 auto',
        width: '100%',
        '& > div': {
            maxHeight: '100%',
            height: 'calc(100vh - 20rem)',
        },
        '@media screen and (min-aspect-ratio: 16/6)': {
            display: 'none',
        },
        '& + *': {
            flex: '0 0 auto',
        },
    },
    backButton: {
        position: 'sticky',
        top: 'calc(20rem - 2.4em - 100vh)',
        zIndex: 1500,
        fontSize: '1.5rem',
        '& a': {
            padding: 8,
            position: 'absolute',
            marginTop: 'calc(100vh - 20rem + 2.5em)',
            top: 0,
            left: -50,
            zIndex: 100,
            [theme.breakpoints.down('sm')]: {
                top: '6rem',
                marginTop: 'calc(100vh - 23rem + 2.5em)',
            },
        },
        [theme.breakpoints.down('sm')]: {
            top: 'calc(18rem - 100vh)',
            '& a': {
                left: -50,
            },
        },
        [theme.breakpoints.down('xs')]: {
            '& a': {
                left: -35,
            },
        },
    },
});


const SubPage = ({image, header, back, children, classes}) => {
    if(header){
        header = (
            <div className={classes.headerContent + ' container'}>
                {header}
            </div>
        );
    }
    let link = null;
    if(back){
        link = (
            <div className={classes.backButton+' container'}>
                <Link to={back} className={"touchable"}>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faArrowLeft} transform={{size: 10}} className={'invert'}/>
                    </span>
                </Link>
            </div>
        );
    }
    return (
        <article>
            {link}
            <header className={classes.header}>
                <div className={classes.headerImage}>
                    {image}
                </div>
                {header}
            </header>
            {children}
        </article>
    );
};

export default injectSheet(styles)(SubPage);