import React from 'react'
import injectSheet from 'react-jss';

const styles = theme => ({
    root: {
        marginTop: '2rem',
        border: '2px solid ' + theme.palette.primary.dark,
        borderRadius: '4px',
//        paddingBottom: 24,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '2px solid' + theme.palette.primary.dark,
        textTransform: 'uppercase',
        '& h3, & h4':{
            boxSizing: 'inherit',
            margin: 0,
            marginRight: 30,
            padding: '1rem 24px',
            flex: '0 1 auto',
            borderRight: '2px solid' + theme.palette.primary.dark,
        },
    },
    control: {
        flex: '0 1 auto',
        display: 'flex',
        width: 200,
        borderLeft: '2px solid' + theme.palette.primary.dark,
    },
    content: {
        display: 'flex',
        flexFlow: 'row wrap',
        '& img': {
            borderRadius: 4,
        },
    },
});

const Box = ({classes, children, header, smallHeader=false, margin=true, controller, postHeader, className}) => {
    header = smallHeader? (<h4>{header}</h4>): (<h3>{header}</h3>);
    const contentStyle = margin? {
        marginLeft: 24,
        marginRight: 24,
    }: {};
    return (
        <section className={classes.root + ' ' + className}>
            <header className={classes.header}>
                {header}
                <div className={classes.control}>{controller}</div>
            </header>
            {postHeader}
            <div className={classes.content} style={contentStyle}>
                {children}
            </div>
        </section>
    );
};
export default injectSheet(styles)(Box);