import {connect} from "react-redux";

import Lightbox from 'react-images';

import {closeLightbox, nextLightbox, previousLightbox} from '../actions';

const mapStateToProps = ({gallery}, {imageSets}) => ({
    images: imageSets,
    isOpen: gallery.lightbox,
    currentImage: gallery.currentImage,
});

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(closeLightbox()),
    onClickPrev: () => dispatch(previousLightbox()),
    onClickNext: () => dispatch(nextLightbox()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Lightbox);
