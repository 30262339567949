import React from 'react'
import injectSheet from 'react-jss';

const styles = theme => ({
    root: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2rem',
        border: '2px solid ' + theme.palette.primary.dark,
        borderRadius: 4,
        marginLeft: '1rem',
        marginRight: '1rem',
        '&:first-child': {
            marginLeft: '0rem',
        },
        '&:last-child': {
            marginRight: '0rem',
        },
    },
    header: {
        display: 'flex',
        borderBottom: '2px solid' + theme.palette.primary.dark,
    },
    title: {
        boxSizing: 'inherit',
        margin: 0,
        marginRight: 30,
        padding: '1rem 24px',
        flex: '0 1 auto',
        borderRight: '2px solid' + theme.palette.primary.dark,
    },
    control: {
        flex: '1 1 auto',
    },
});

const SmallBox = ({classes, children, header, controller, style, className, simpleHeader=false}) => (
    <section className={classes.root + " "+className} style={style}>
        <header className={simpleHeader? classes.simpleHeader: classes.header}>
            <h4 className={classes.title}>{header}</h4>
            <div className={classes.control}>{controller}</div>
        </header>
        {children}
    </section>
);
export default injectSheet(styles)(SmallBox);