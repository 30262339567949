import React from 'react';
import Img from 'gatsby-image';
import injectSheet from 'react-jss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListUl} from '@fortawesome/free-solid-svg-icons/faListUl';
import {faImages} from '@fortawesome/free-solid-svg-icons/faImages';

import Link from '../components/Link';

const styles = theme => {
    let fontSize = 1.1;
    return {
        root: {
            [theme.breakpoints.down('md')]: {
                fontSize: '.85em',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1em',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.2em',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1em',
            },
            width: '100%',
            paddingTop: '100%',
            position: 'relative',
        },
        link: {
            position: 'absolute',
            top:0,left:0,right:0,bottom:0,
            display: 'block',
            '&:hover $overlay':{
                left:0,
                opacity: 1,
                fontSize: fontSize+'em',
            },
        },
        image: {
            width: '100%',
            height: '100%',
        },
        overlay: {
            transition: 'opacity .25s ease-in, font-size .25s ease-in, left .25s ease-in',
            color: theme.palette.text.secondary,
            fontSize: fontSize/4*3+'em',
            opacity: .85,
            background: theme.palette.background,
            position: 'absolute',
            top:0,left:'50%',right:0,bottom:0,
            padding: '.3em .3em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        title:{
            lineHeight: '1.1em',
        },
        count:{
            fontSize: '1.5em',
            alignSelf: 'center',
        },
        name:{
            fontSize: '.75em',
            lineHeight: '1em',
            alignSelf: 'flex-end',
        },
    }
};

const GalleryElement = ({classes, image, name, title, count, date, slug}) => {
    let id = (name+'-'+title).split(' ').join('_');
    if(date){
        id = date.format('D-MM');
        date = date.format('D. MMMM');
    }
    return (
        <section className={classes.root}>
            <span id={id} style={{position: 'absolute', top: '-5em', }}></span>
            <Link
                to={slug}
                className={classes.link}
            >
                <Img
                    className={classes.image}
                    fluid={image.fluid}
                />
                <div className={classes.overlay}>
                    <span className={classes.title}>{title}<br/>{date}</span>
                    <span className={classes.count}>
                        {count}&nbsp;
                        <FontAwesomeIcon icon={faImages}/>
                    </span>
                    <span className={classes.name}>© {name}</span>
                </div>
            </Link>
        </section>
    );
};

export default injectSheet(styles)(GalleryElement);